import React, { createContext, useContext, useState } from 'react';

const SelectedFeatureContext = createContext();

export const useSelectedFeatureContext = () => useContext(SelectedFeatureContext);


export const SelectedFeatureProvider = ({ children }) => {
  const [country, setCountry] = useState('');
  const [tradeType, setTradeType] = useState('');
  const [waterType, setWaterType] = useState('');
  const[selectedSector, setSelectedSector]=useState("")
  const [topData, setTopData] = useState(10);



  return (
    <SelectedFeatureContext.Provider value={{ country, setCountry, topData, setTopData, tradeType, setTradeType, waterType, setWaterType,selectedSector ,setSelectedSector}}>
      {children}
    </SelectedFeatureContext.Provider>
  );
};
